@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .z-100 {
      z-index: 100;
    }
  }

/* src/App.css */
.ds-container {
    @apply flex flex-col border-2 bg-[#86EFAC] border-[#86EFAC] rounded-lg overflow-hidden;
}

.screen {
    @apply flex items-center justify-center border border-[#86EFAC] m-1 rounded-lg;
}

.top-screen {
    @apply flex-1 bg-[#1B1D28];
}

/* .bottom-screen {
    @apply h-1/3 bg-[#1B1D28];
} */

.bottom-screen {
    @apply bg-[#1B1D28];
}

.da-container {
    @apply flex flex-col border-2 border-[#86EFAC] rounded-lg overflow-hidden px-8 py-2;
}

.koth-container {
    @apply flex flex-col border-2 border-[#f5f245] rounded-lg overflow-hidden;
}

.chart-container {
    @apply flex flex-col rounded-lg overflow-hidden;
}

/* Custom styles for the Wallet Modal */
.wallet-adapter-modal-wrapper {
    @apply bg-[#1B1D28] flex flex-col border-2 border-[#86EFAC] rounded-lg overflow-hidden p-8;
}

/* Custom styles for the Wallet Modal */
.wallet-adapter-modal {
    @apply bg-[#1e293b2c] text-white rounded-lg;
}

/* Custom styles for the Wallet Modal */
.wallet-adapter-button {
    @apply bg-[#1B1D28] ring-2 ring-[#86EFAC] text-[#86EFAC] rounded-lg transition-colors duration-200 p-2 px-4 h-full leading-none;
}

.wallet-adapter-button:not([disabled]):hover {
    @apply bg-[#86EFAC];
}

.wallet-adapter-button:hover {
    background-color: #86EFAC; /* Lighter green on hover */
    color: #1B1D28;
}

.wallet-adapter-modal-button-close {
    @apply bg-[#1B1D28] hover:bg-[#86EFAC] ring-2 ring-[#86EFAC] rounded-lg transition-colors duration-200 p-2;
}

.wallet-adapter-modal-list {
    @apply rounded-lg space-y-4;
}

.wallet-adapter-dropdown-list {
    /* @apply ring-2 ring-[#86EFAC] bg-[#1b1d28b6] backdrop-saturate-150 backdrop-blur-md; */
    @apply ring-2 ring-[#86EFAC];
}

.wallet-adapter-modal-list li>button {
    @apply rounded-lg !important;
}

/* Wallet list item styles */
.wallet-adapter-modal-list-item {
    background-color: #374151; /* Darker background for items */
    color: white;
    border-radius: 6px;
    margin-bottom: 8px;
    padding: 10px;
    transition: background-color 0.2s;
}

.wallet-adapter-modal-list-item:hover {
    background-color: #4B5563; /* Darker hover state */
}

/* Close button */
.wallet-adapter-modal-button-close {
    color: white;
    transition: color 0.2s;
}

.wallet-adapter-modal-button-close:hover {
    color: #34D399; /* Green color on hover */
}

.wallet-adapter-dropdown-list-item {
    @apply bg-[#1B1D28] ring-2 ring-[#86EFAC] text-[#86EFAC] hover:text-[#1B1D28] hover:ring-[#1B1D28] rounded-lg transition-colors duration-200 p-4 h-full leading-none;
}

.wallet-adapter-dropdown-list {
    @apply z-50;
}

.settings-icon {
    @apply bg-[#1B1D28] hover:bg-[#86EFAC] text-[#86EFAC] hover:text-[#1B1D28] ring-2 ring-[#86EFAC] rounded-lg transition-colors duration-200 p-2 h-full;
}

.navbar-balance {
    @apply bg-[#1B1D28] ring-2 ring-[#86EFAC] text-[#86EFAC] rounded-lg transition-colors duration-200 p-2 px-4 h-full items-baseline;
}

.css-1umw9bq-MuiSvgIcon-root {
    color: #86EFAC; /* Lighter green on hover */
}

.audio-button {
    @apply bg-[#1B1D28] hover:bg-[#86EFAC] text-[#86EFAC] hover:text-[#1B1D28] ring-2 ring-[#86EFAC] rounded-lg transition-colors duration-200 p-2 h-full;
}
